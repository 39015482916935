import React from "react";
import { NavLink } from "react-router-dom";
import "./sidenav.scss";

interface IItem {
  to: string;
  name: string;
  isActive?: boolean;
  items?: Array<IItem>;
}

interface Props {
  items: Array<IItem>;
  title?: string;
}

const Sidenav: React.FC<Props> = (props: Props) => {
  const { items, title } = props;

  return (
    <div className="sidenav">
      {title ? <h5>{title}</h5> : null}
      <nav>
        {items.map((item: IItem, index: number) => {
          return (
            <React.Fragment key={index}>
              <NavLink className={item.isActive ? "is-active" : ""} activeClassName="is-active" exact to={item.to}>
                {item.name}
              </NavLink>
              {/*{item.items && item.items.length > 0 && item.isActive ? (*/}
              {/*  <div className="submenu">*/}
              {/*    {item.items.map((subitem, indexx) => {*/}
              {/*      return (*/}
              {/*        <NavLink key={indexx} className={subitem.isActive ? "is-active" : ""} activeClassName="is-active" exact to={subitem.to}>*/}
              {/*          {subitem.name}*/}
              {/*        </NavLink>*/}
              {/*      );*/}
              {/*    })}*/}
              {/*  </div>*/}
              {/*) : null}*/}
            </React.Fragment>
          );
        })}
      </nav>
    </div>
  );
};

export default Sidenav;

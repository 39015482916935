import React, { useEffect, useState } from "react";
import ContactSection from "../../sections/contact-section/contact-section";
import HeroSectionWrapper from "./sections/hero-section-wrapper";
import "./categories-page.scss";
import CtaSectionWrapper from "./sections/cta-section-wrapper";
import ProductsSection from "./sections/products-section";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { RoutePath } from "../../route-paths";
import { generatePath, useParams } from "react-router-dom";
import { allCategoriesData } from "./data/categories-data";

const CategoryPage: React.FC = () => {
  let { categoryId } = useParams<{ categoryId: string }>();
  const [category, setCategory] = useState("");

  useEffect(() => {
    const item = allCategoriesData.find((x) => x.url === categoryId);
    if (categoryId === "wszystkie") setCategory("wszystkie");
    else setCategory(item?.tile?.title ?? "");
  }, [categoryId]);

  return (
    <div className="categories-page">
      <BreadcrumbsItem to={RoutePath.CATEGORIES}>Produkty</BreadcrumbsItem>
      <BreadcrumbsItem to={generatePath(RoutePath.CATEGORY, { categoryId: categoryId })}>{category}</BreadcrumbsItem>
      <HeroSectionWrapper />
      <ProductsSection />
      <CtaSectionWrapper />
      <ContactSection />
    </div>
  );
};

export default CategoryPage;

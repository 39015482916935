import React from "react";
import Button from "../../../components/button/button";
import HeroSection from "../../../sections/hero-section/hero-section";
import HeroImg from "./../../../assets/aspiratory-twomet.png";

interface Props {
  scrollToRef: any;
}

const HeroSectionWrapper: React.FC<Props> = (props: Props) => {

  return (
    <HeroSection img={HeroImg} alt="Example img">
      <h1>Spółdzielnia<br/> Two-Met</h1>
      <p>
        Od 01.10.2022 Ekohigiena Aparatura Oddział Zgierz
      </p>
      <Button onClick={() => {window.location.href = "https://ekohigiena.com.pl/";}}>
        Zobacz stronę firmy Ekohigiena
      </Button>
    </HeroSection>
  );
};

export default HeroSectionWrapper;

import React from "react";
import TitleBlock from "../../../components/title-block/title-block";
import {allCategoriesData} from "../../categories-page/data/categories-data";
import {generatePath} from "react-router-dom";
import TextBlock from "../../../components/writing-tools/text-block/text-block";
import PostBlock from "../../../components/writing-tools/post-block/post-block";
import Col from "../../../components/grid/col";
import Grid from "../../../components/grid/grid";
import Row from "../../../components/grid/row";
import BlogSection from "../../../sections/blog-section/blog-section";

interface Props {
  setRef: any;
}

const HomeInfoSectionWrapper: React.FC<Props> = (props: Props) => {
  return (
    <div ref={props.setRef}>
      <TitleBlock title="Szanowni Klienci Spółdzielni Inwalidów Two-Met"
                  subtitle=""/>
      <BlogSection>
        <PostBlock>
          <TextBlock>
            <p>Informujemy, że w dniu 2022-10-01 nastąpiło przejęcie przesz spółkę EKOHIGIENA APARATURA Ryszard
              Putyra
              Spółka
              Jawna, działalności Spółdzielni Inwalidów „TWO-MET” w zakresie produkcji, serwisu, sprzedaży
              aspiratorów
              oraz
              innych urządzeń marki TWO-MET.<br/><br/>

              EKOHIGIENA APARATURA Ryszard Putyra Spółka Jawna stała się wyłącznym właścicielem wszelkich praw
              związanych
              z
              urządzeniami marki TWO-MET jak również przejęła obowiązki związane z gwarancją udzieloną przez
              TWO-MET
              na
              urządzenia podlegające jeszcze gwarancji.<br/><br/>

              Jednocześnie w dniu 2022-10-01 został utworzony w Zgierzu oddział firmy EKOHIGIENA APARATURA oraz
              nastąpiło
              przejęcie pracowników TWO-MET. Dzięki temu EKOHIGIENA APARATURA posiada niezbędne zasoby oraz
              umiejętności w
              celu produkcji i serwisowania urządzeń TWO-MET w tym urządzeń stosowanych w górnictwie.<br/><br/>

              Liczmy na dalszą dobrą współpracę z Państwem. Oczywiście w razie jakichkolwiek wątpliwości z Państwa
              strony
              proszę zwracać się do nas, chętnie odpowiemy na wszelkie pytania. Do każdego naszego klienta
              podchodzimy
              indywidualnie. Kontaktując się z nami, uzyskają Państwo fachowe wsparcie w wyborze produktów i ich
              późniejszej
              eksploatacji.<br/><br/>
            </p>
          </TextBlock>
        </PostBlock>
      </BlogSection>
    </div>

  );
};

export default HomeInfoSectionWrapper;

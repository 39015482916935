import "./App.scss";
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { History } from "history";
import { RoutePath } from "./route-paths";
import { compose } from "redux";
import { connect, shallowEqual, useSelector } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import SlideOverlay from "./components/slide-overlay/slide-overlay";
import HomePage from "./pages/home-page/home-page";
import { select_is_navbarOpenActive } from "./redux/callbacksState/callbacksState.selectors";
import CookiePolicyPopup from "./components/cookie-policy-popup/cookie-policy-popup";
import Navbar from "./components/navbar/navbar";
import Footer from "./sections/footer/footer";
import PrefooterSection from "./sections/prefooter-section/prefooter-section";
import ContactPage from "./pages/contact-page/contact-page";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import ProductPage from "./pages/categories-page/product-page";
import CategoriesPage from "./pages/categories-page/categories-page";
import CategoryPage from "./pages/categories-page/category-page";
import RulesPage from "./pages/rules-page/rules-page";

interface Props {
  match: any;
  history?: History;
}

const App: React.FC<Props> = () => {
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  return (
    <>
      <SlideOverlay />
      <Navbar />
      <div className={`overlay${is_navbarOpenActive ? " isActive" : ""}`}>
        <BreadcrumbsItem to={RoutePath.HOME}>Strona główna</BreadcrumbsItem>
        <Switch>
          <Route exact path={RoutePath.CATEGORIES} render={() => <CategoriesPage />} />
          <Route exact path={RoutePath.CATEGORY} render={() => <CategoryPage />} />
          <Route exact path={RoutePath.PRODUCT} render={() => <ProductPage />} />
          <Route exact path={RoutePath.PRODUCT} render={() => <ProductPage />} />
          <Route exact path={RoutePath.RULES} render={() => <RulesPage />} />
          <Route exact path={RoutePath.CONTACT} render={() => <ContactPage />} />
          <Route path={RoutePath.HOME} render={() => <HomePage />} />


        </Switch>
        {/*<PrefooterSection />*/}
        {/*<Footer />*/}
      </div>
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <CookiePolicyPopup />
    </>
  );
};

export default compose(withRouter, connect(null, null))(App);

import React from "react";
import Container from "../../components/container/container";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import "./contact-section.scss";
import TitleBlock from "../../components/title-block/title-block";
import ExamplePerson from "./../../assets/examplePerson.jpg";

const ContactSection: React.FC = () => {
  return (
    <section className="contact-section">
      <Container>
        <TitleBlock title="Skontakuj się z nami"/>
        <Grid>
          <Row gap={16} className="contact-wrapper">
            {/*<Col size={[2, 4, 4, 12]} offset={[2, 0]} className="contact-person">*/}
            {/*  <img src={ExamplePerson} alt="Ryszard Putyra, CEO" />*/}
            {/*  <p><b>Ryszard Putyra, CEO</b></p>*/}
            {/*</Col>*/}
            {/*<Col size={[3, 4, 4, 12]}>*/}
            {/*  <h4>Dane właściciela</h4>*/}
            {/*  <p>*/}
            {/*    Ekohigiena Aparatura Ryszard Putyra Sp.J.*/}
            {/*  </p>*/}
            {/*  <p>*/}
            {/*    ul. Strzelecka 19, <br />*/}
            {/*    Środa Śląska 55-300*/}
            {/*  </p>*/}
            {/*  <p>*/}
            {/*    NIP: 913-160-96-86 <br />*/}
            {/*    Regon: 021292911 <br />*/}
            {/*    KRS: 0000356994*/}
            {/*  </p>*/}
            {/*</Col>*/}
            <Col size={[4, 4, 4, 12]}>
              <h4>Adres oddziału</h4>
              <p><b>Ekohigiena Biuro Zgierz:</b> <br/>ul. Hoża 4<br/>95-100 Zgierz</p>
              <p><b>Zadzwoń:</b><br/>(+48) 42 716-39-03</p>
              <p><b>Napisz:</b> <br/>biurotwo@ekohigiena.com.pl</p>
            </Col>
            <Col size={[4, 4, 4, 12]}>
              <h4>Kierownik oddziału</h4>
              <p>Monika Szczepaniak</p>
              <p><b>Zadzwoń:</b><br/>(+48) 42 716-39-03 <br/>(+48) 451-545-539</p>
              <p><b>Napisz:</b> <br/>monika.szczepaniak@ekohigiena.com.pl</p>
            </Col>
            <Col size={[4, 4, 4, 12]}>
              <h4>Dział handlowy i serwis</h4>
              <p>Jacek Smus</p>
              <p><b>Zadzwoń:</b><br/>(+48) 42 716-31-21 <br/> (+48) 517-272-178</p>
              <p><b>Napisz:</b> <br/>jacek.smus@ekohigiena.com.pl</p>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  )
}

export default ContactSection;
import React from "react";
import { generatePath } from "react-router-dom";
import { ProductDto } from "../../dto/productDto";
import { allCategoriesData } from "../../pages/categories-page/data/categories-data";
import { RoutePath } from "../../route-paths";
import Row from "../grid/row";
import ProductTile from "../product-tile/product-tile";
import './RelatedProducts.scss';

interface RelatedProductsProps {
  items: Array<string | { categoryId: string, productId: string }>
}

export interface RelatedProductDto {
  categoryId: string;
  product: ProductDto;
}

const RelatedProducts: React.FC<RelatedProductsProps> = (props) => {
  const { items } = props;

  const findProductByProductId = (id: string): RelatedProductDto | undefined => {
    let categoryIndex = allCategoriesData.findIndex((category) => category.products.findIndex((product) => product.url === id) !== -1);
    if (categoryIndex === -1) return undefined;
    return getByCategoryIndexAndProductId(categoryIndex, id);
  }

  const findProductByCategoryIdAndProductId = (categoryId: string, productId: string): RelatedProductDto | undefined => {
    let categoryIndex = allCategoriesData.findIndex((category) => category.url === categoryId);
    if (categoryIndex === -1) return undefined;
    return getByCategoryIndexAndProductId(categoryIndex, productId);
  }

  const getByCategoryIndexAndProductId = (categoryIndex: number, productId: string): RelatedProductDto | undefined => {
    let category = allCategoriesData[categoryIndex];
    let productIndexInCategory = category.products.findIndex((product) => product.url === productId);
    if (productIndexInCategory === -1) return undefined;
    let product = category.products[productIndexInCategory];

    return product ? { product: product, categoryId: category.url} : undefined;
  }

  const findProductByItem = (item: string | { categoryId: string, productId: string }): RelatedProductDto | undefined => {
    var product;

    if (typeof item === "string") product = findProductByProductId(item)
    else product = findProductByCategoryIdAndProductId(item.categoryId, item.productId);

    if (product) return product;
    else alert("Nie można wyszukać: " + JSON.stringify({ item: item }));
  }

  return (
    <div className="RelatedProducts">
      <Row gap={16}>
        {items && items.length > 0 ? items.map((item: string | { categoryId: string, productId: string }, index: number) => {
          var relatedProduct = findProductByItem(item);
          return (
            <>
              {relatedProduct ?
                <ProductTile key={index} product={relatedProduct.product} to={generatePath(RoutePath.PRODUCT, { categoryId: relatedProduct.categoryId, productId: relatedProduct.product.url })}>
                  {relatedProduct.product.tile.title}
                </ProductTile>
                : null}
            </>
          );
        }) : null}
      </Row>
    </div>
  )
};

export default RelatedProducts;


import React, {useRef} from "react";
import ContactSection from "../../sections/contact-section/contact-section";
import HeroSectionWrapper from "./sections/hero-section-wrapper";
import HomeInfoSectionWrapper from "./sections/home-info-section-wrapper";
import "./home-page.scss";

const HomePage: React.FC = () => {
  const heroTarget = useRef<any>();

  return (
    <div className="home-page">

      <HeroSectionWrapper scrollToRef={heroTarget}/>
      <HomeInfoSectionWrapper setRef={heroTarget}/>
      <ContactSection/>
    </div>
  );
};

export default HomePage;
